var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card",
    [
      _c("div", { attrs: { slot: "header" }, slot: "header" }, [
        _vm._v(" " + _vm._s(_vm.title) + " "),
        _c(
          "span",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.source == "spotify",
                expression: "source == 'spotify'"
              }
            ],
            staticStyle: { float: "right" }
          },
          [
            _vm._v(" Source: Spotify "),
            _c("i", { staticClass: "fa fa-spotify" })
          ]
        ),
        _c(
          "span",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.subTitle,
                expression: "subTitle"
              }
            ],
            staticStyle: { float: "right" }
          },
          [_vm._v(" " + _vm._s(_vm.subTitle) + " ")]
        )
      ]),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.trendsLoading,
              expression: "trendsLoading"
            }
          ]
        },
        [_c("b-spinner", { attrs: { label: "Spinning" } })],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.loadingError,
              expression: "loadingError"
            }
          ]
        },
        [_vm._v(" Er is een fout opgetreden tijdens het laden. ")]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.countTotal == 0,
              expression: "countTotal == 0"
            }
          ]
        },
        [_vm._v(" Geen gegevens beschikbaar ")]
      ),
      _c("b-table", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.countTotal > 0,
            expression: "countTotal > 0"
          }
        ],
        attrs: { striped: "", small: "", fields: _vm.fields, items: _vm.items },
        scopedSlots: _vm._u([
          {
            key: "head(title)",
            fn: function() {
              return [_vm._v(" " + _vm._s(_vm.columnTitle) + " ")]
            },
            proxy: true
          },
          {
            key: "cell(title)",
            fn: function(row) {
              return [_vm._v(" " + _vm._s(row.item.title) + " ")]
            }
          },
          {
            key: "cell(count)",
            fn: function(row) {
              return [
                _vm._v(
                  " " + _vm._s(_vm._f("numberFormat")(row.item.count)) + " "
                )
              ]
            }
          },
          {
            key: "cell(percentage)",
            fn: function(row) {
              return [
                _vm._v(
                  " " +
                    _vm._s(_vm._f("numberFormat")(row.item.percentage)) +
                    " % "
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }